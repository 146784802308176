<template>
  <div>
    
    <h3 class="primary darken-2 pa-2 white--text font-weight-bold">Send a message</h3>
    <v-card class="pa-4">
      <v-form @submit.prevent="sendMessage">
        

        <v-select
        v-model="message.u_or_g"
        :items="UorG"
        outlined dense
        label="User or Group ?"
      ></v-select>

        <v-row v-if=" message.u_or_g == 'User'">
        <v-col cols="10" class="py-0 my-1">
          <v-text-field
            v-model="admin_username"
            ref="admin_username"
            outlined dense
            label="Search user..."
          ></v-text-field>
        </v-col>

        <v-col cols="2" class="py-0 my-1">
          <v-btn
            color="success"
            class="me-3"
            fab small
            @click="getUsers()"
          >
            <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          class="py-0 my-1"
        >
          <v-select
            v-model="selected_user"
            ref="selected_user"
            :items="users"
            item-text="username"
            item-value="id"
            outlined dense
            label="Select user"
          ></v-select>
        </v-col>
        </v-row>

        <v-row v-if=" message.u_or_g == 'Group'">
          <v-col cols="10" class="py-0 my-1">
            <v-text-field
              v-model="group_name"
              ref="group_name"
              outlined dense
              label="Search njangi group..."
            ></v-text-field>
          </v-col>
  
          <v-col cols="2" class="py-0 my-1">
            <v-btn
              color="success"
              class="me-3"
              fab small
              @click="getGroups()"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </v-btn>
          </v-col>
  
          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selected_group"
              ref="selected_group"
              :items="groups"
              item-text="groupname"
              item-value="groupname"
              outlined dense
              label="Select the njangi group"
            ></v-select>
          </v-col>
          </v-row>

        <v-text-field v-model="message.subject" label="Subject" required outlined dense></v-text-field>
        <v-textarea v-model="message.content" label="Message" required outlined dense></v-textarea>

        <v-select
          v-model="message.section"
          :items="sections"
          label="Section"
          required
          outlined dense
        ></v-select>

        <v-btn type="submit" color="primary" class="mt-3">Send</v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      message: {
        receiver_id: "",
        group:"",
        subject: "",
        content: "",
        section: "",
        u_or_g:"",
      },
      UorG: ["User","Group"],
      selected_user: "",
      selected_group: "",
      users: [],
      groups: [],
      sections: ["Savings", "Market Place", "Njangi"]
    };
  },
  methods: {
    getUsers(){
      axios
        .get('/api/v1/manager/get/clients/'+this.admin_username+'/')
        .then(response => {
          this.users = response.data  // get the data and fill into users
          console.log(this.users)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },
    getGroups(){
      axios
        .get('/api/v1/manager/get/groups/'+this.group_name+'/')
        .then(response => {
          this.groups = response.data  
          console.log(this.groups)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },
    async sendMessage() {
      this.$store.commit("setIsLoading", true);
      this.message.receiver_id=this.selected_user
      this.message.group=this.selected_group
      try {
        const response = await axios.post("/api/v1/manager/message_manager/", this.message);

        this.$store.commit("setSnackBarMessage", response.data.success);
        this.$store.commit("setSnackBarColor", "green");
        this.$store.commit("activateSnackBar", true);

        // Réinitialiser le formulaire
        this.message = { receiver_id: "",group:"", subject: "", content: "", section: "",u_or_g:"" };
      } catch (error) {
        let errorMessage = "An error has occurred.";
        if (error.response && error.response.data.error) {
          errorMessage = error.response.data.error;
        }
        this.$store.commit("setSnackBarMessage", errorMessage);
        this.$store.commit("setSnackBarColor", "red");
        this.$store.commit("activateSnackBar", true);
      }

      this.$store.commit("setIsLoading", false);
    },

  
  }
};
</script>
